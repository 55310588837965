<template>
    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <label for="">First Name</label>
                <input type="text" class="form-control" v-model="input.first_name"/>
                <span class="text-danger">{{getError('first_name')}}</span>
            </div>
            <div class="form-group">
                <label for="">Last Name</label>
                <input type="text" class="form-control" v-model="input.last_name"/>
                <span class="text-danger">{{getError('last_name')}}</span>
            </div>
            <div class="form-group">
                <label for="">Company</label>
                <input type="text" class="form-control" v-model="input.company"/>
                <span class="text-danger">{{getError('company')}}</span>
            </div>

            <div class="form-group">
                <label for="">Phone</label>
                <input type="text" class="form-control" v-model="input.phone"/>
                <span class="text-danger">{{getError('phone')}}</span>
            </div>
            <div class="form-group">
                <label for="">Email</label>
                <input type="email" class="form-control" v-model="input.email_address"/>
                <span class="text-danger">{{getError('email')}}</span>
            </div>
            <div class="form-group">
                <label for="">State</label>
                <select v-model="input.state_id" class="form-control" id="">
                    <option value="0">Select State</option>
                    <option v-for="state in states" :value="state.id" v-bind:key="state.id">{{state.name}}</option>
                </select>
                <span class="text-danger">{{getError('state_id')}}</span>
            </div>
            <div class="form-group">
                <label for="">Zip Code</label>
                <input type="text" class="form-control"
                       v-model="zip_code"/>
                <span class="text-danger" v-if="valid_zip_code==-1">Zip code is not valid</span>
                <span class="text-danger">{{getError('zip_code_id')}}</span>
            </div>
            <div class="form-group">
                <label for="">Street Address</label>
                <input type="text" class="form-control" v-model="input.street_address1"/>
                <span class="text-danger">{{getError('street_address1')}}</span>
            </div>
            <div class="form-group">
                <!--<label for="">First Name</label>-->
                <input type="text" class="form-control" v-model="input.street_address2"/>
                <span class="text-danger">{{getError('street_address2')}}</span>
            </div>

            <input type="button" v-if="!loading" @click="store" class="btn btn-sm btn-outline-info btn-wide"
                   value="Save">
            <input type="button" @click="$emit('hide')" class="btn btn-danger ml-2 btn-sm btn-wide" value="Close">

        </div>
    </div>
</template>
<script>
    import {Paths} from '@/paths';
    import Form from '@/forms';
    import lists from '@/mixins/list.js';
    export default{
        extends: Form,
        mixins: [lists],
        props: ['address'],
        data(){
            return {
                states: [],
                zip_code: '',
                valid_zip_code: 0,

                input: {
                    first_name: '',
                    last_name: '',
                    company: '',
                    street_address1: '',
                    street_address2: '',
                    state_id: 0,
                    zip_code_id: 0,
                    phone: '',
                    email_address: '',
                }
            }
        },
        methods: {
            loadStates(){
                this.states = this.statesList().then(response => this.states = response.data);
            },

            store(){
                let app = this;
                app.startRequest();
                this.axios.post(Paths.billingAddress.store, app.input).then(() => {
                    app.$emit('added');
                }).catch((error) => {
                    app.setErrors(error.response);
                }).finally(() => app.endRequest());
            },
            loadDefault(){
                this.input.first_name = this.address.first_name;
                this.input.last_name = this.address.last_name;
                this.input.email_address = this.address.email_address;
                this.input.phone = this.address.phone;
//                this.input.street_address1 = this.address.address;
                this.input.company = this.address.company;
                this.input.state_id = this.address.state_id;
                this.input.street_address1 = this.address.street_address1;
                this.zip_code = this.address.zip_code.code;
            }

        },
        watch: {
            address(){
                this.loadDefault();
            },
            valid_zip_code(val){
                if (val == -1) {
                    this.input.zip_code_id = 0;
                    return;
                }
                this.input.zip_code_id = val;
            },
            zip_code(val){
                console.log(val);
                if (val.length < 3) {
                    this.valid_zip_code = -1;
                    return;
                }
                this.validateZip(val, this.input.state_id).then(response => this.valid_zip_code = parseInt(response.data));

            },
        },

        mounted(){

            this.loadStates();
            this.loadDefault();

        }
    }
</script>