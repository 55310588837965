<template>
    <div class="pt-2" v-if="address!=undefined && address!=null">
        <p>Name : {{full_name}}</p>
        <p>Company : {{company}}</p>
        <p>Phone : {{phone}} </p>
        <P>Email: {{email}}</P>
        <p>Address : {{full_address}}</p>
    </div>
</template>
<script>
    export default{
        props: ['address'],
        methods: {
            hasValue(val){
                if (val == null) {
                    return false;
                }
                if (val.length == 0) {
                    return false;
                }
                return true;
            }
        },
        computed: {
            full_name(){
                return this.address.first_name + ' ' + this.address.last_name;
            },
            phone(){
                return this.hasValue(this.address.phone) ? this.address.phone : 'none';
            },
            email(){
                return this.hasValue(this.address.email_address) ? this.address.email_address : 'none';
            },
            company(){
                return this.hasValue(this.address.company) ? this.address.company : 'none';
            },
            full_address(){
                return this.address.full_address;
//                let address = this.address.street_address1;
//                address += ', ' + this.address.state.name;
//                address += ', ' + this.address.zip_code.code;
//                return address;
            }
        },
        mounted(){

        }
    }
</script>