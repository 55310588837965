<template>
    <div class="row ">
        <div class="col-12 text-success font-italic" v-if="loading">{{loading_message}}</div>
        <div v-else class="col-12 ">

            <div class="row">

                <div class="col-sm-10 mt-2  ">
                    <!--<select id="" class="form-control" v-model="selected_index">-->
                    <!--<option value="-1">Select delivery address</option>-->
                    <!--<option v-for="(address,index) in addresses" v-bind:key="address.id" :value="index">-->
                    <!--{{address.full_address}}-->
                    <!--</option>-->
                    <!--</select>-->
                    <show-address :address="selected_address"></show-address>
                </div>
            </div>

        </div>
        <div class="col-12 mt-2">
            <input type="button" @click="add=!add" class="btn btn-outline-info btn-sm "
                   value="Change Shipping Address ">
        </div>
        <div class="col-12 mt-2" v-if="add">
            <div class="row">
                <div class="col-sm-10">
                    <add-form :address="selected_address" v-on:added="added()" v-on:hide="add=false;"></add-form>
                </div>
            </div>

        </div>

    </div>

</template>
<script>
    import {Paths} from '@/paths';
    import loader from '@/loader';
    import AddForm from '@/views/address/delivery/Create';
    import ShowAddress from '@/views/address/Show';
    export default{
        extends: loader,
        components: {AddForm, ShowAddress},
        data(){
            return {
                addresses: [],
                loading_message: 'Loading billing address information ...',
                add: false,
                selected_address: null,
                selected_index: -1,
            }
        },
        watch: {
            selected_address(val){
                this.$emit('selected', val);
            },
            selected_index(val){
                this.selected_address = this.addresses[val];
            },
            addresses(val){

                if (val.length == 0) {
                    return;
                }
//                let address = this._.first(val);
//                if (address == undefined) {
//                    return;
//                }
//                if (val.length > 1) {
//                    return;
//                }
                let app = this;

                app.$store.commit('setDeliveryAddressInCart', val[0]);
                this.selected_index = 0;
            },
        },
        methods: {
            added(){
                this.loading_message = 'Refreshing address information...';
                this.add = false;
                this.load();
            },
            load(){
                let app = this;
                app.startLoading();
                this.axios.post(Paths.deliveryAddress.index).then((response) => {
                    app.addresses = response.data;
                    app.selected_index = -1;
                }).finally(() => app.endLoading());
            },
            getClass(index, address_id){
                if (address_id == this.selected_address) {
                    return 'bg-success';
                }

                if (index % 2 == 0) {
                    return 'bg-light';
                }
                return 'bg-white';
            },
        },
        mounted(){
            this.load();
        }
    }
</script>